export default {
  allArticles: ' All articles',
  refresh: 'Refresh',
  cancel: 'Cancel',
  ok: 'OK',
  clear: 'Clear',
  close: 'Close',
  back: 'Back',
  delete: 'Delete',
  discard: 'Discard',
  confirm: 'Confirm',
  more: 'More',
  search: 'Search',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  email: 'Email',
  loading: 'Loading',
  date: 'Date',
  retry: 'Retry',
  exit: 'Exit'
}
