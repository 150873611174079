import { storeToRefs } from 'pinia'
import { clientStorage } from './clientStorage'
import { useGlobalStore } from '../stores/global'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import { watch } from 'vue'
import { createSingletonSetup } from './common/singleton'

export type MessageLanguages = keyof typeof messages
export type MessageSchema = (typeof messages)['en-GB']

/* eslint-disable @typescript-eslint/no-empty-object-type */
declare module 'vue-i18n' {
  // define the locale messages schema
  export interface DefineLocaleMessage extends MessageSchema {}

  // define the datetime format schema
  export interface DefineDateTimeFormat {}

  // define the number format schema
  export interface DefineNumberFormat {}
}

export const useI18n = createSingletonSetup(() => {
  const storedLocale = clientStorage.getLocale() ?? 'en-GB'
  const globalStore = useGlobalStore()
  const { locale } = storeToRefs(globalStore)

  if (isString(storedLocale)) {
    const keys = Object.keys(messages)
    if (keys.includes(storedLocale)) {
      locale.value = storedLocale as MessageLanguages
    }
  }

  const i18n = createI18n({
    legacy: false,
    locale: storedLocale,
    globalInjection: true,
    messages
  })

  watch(
    () => locale.value,
    (v) => {
      clientStorage.setLocale(v)

      // vue-i18n supports both Options API (legacy) and Composition API
      // locale.value is used because we're not using legacy mode
      i18n.global.locale.value = v
    }
  )

  return {
    i18n,
    t: i18n.global.t
  }
})

function isString(test: unknown): test is string {
  return typeof test === 'string'
}
