export default {
  title: 'Återbetala',
  titleRefund: 'Återbetala',
  titleReversal: 'Makulera',
  infoReversal: 'Reversering kan bara göras på hela beloppet',
  details: 'Detailjer',
  orderNumber: 'Order',
  total: 'Totalt',
  rows: 'Rader',
  rowsToRefund: 'Rader att återbetala',
  all: 'Alla',
  buttonRefund: 'Återbetala',
  buttonBack: 'Tillbaka',
  buttonClose: 'Stäng',
  buttonReset: 'Nollställ',
  refundCode: 'Återbetalningskod',
  cancelRefund: 'Avbryt återbetalning',
  refundSuccess: 'Återbetalning lyckades',
  incomplete: 'Återbetalning ej slutförd',
  verifying: 'Verifierar återbetalning',
  error: {
    noPayments: 'Ej återbetalbar',
    multiplePayments: 'Återbetalning stöds inte',
    paymentMethodUnavailable: 'Betalmedel är inte tillgängligt',
    paymentMethodBusy: 'Betalmedel är upptaget',
    initFailed: 'Begäran misslyckades',
    verifyIncomplete: 'Återbetalning inkomplett',
    verifyTimeout: 'Återbetalning timeout',
    failed: 'Återbetalning misslyckades',
    invalidType: 'Felaktig ordertyp'
  }
}
