import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useFeatureStore } from './feature'
import { LocationResponse, MerchantResponse, UserInfo } from 'src/coreApi'
import { MessageLanguages } from 'src/plugins/i18n'

export const useGlobalStore = defineStore('global', () => {
  const featureStore = useFeatureStore()
  const locale = ref<MessageLanguages>('sv-SE')
  const user = ref<UserInfo | null>(null)
  const authPending = ref(false)
  const returnTo = ref<string>()
  const isKeypadOpen = ref(false)
  const keypadValue = ref<string>('')

  const isAuthenticated = computed(() => {
    return user.value?.isAuthenticated === true
  })

  const isActive = computed(() => {
    return user.value?.emailConfirmed === true && user.value?.merchantActive === true
  })

  const isMerchantUser = computed(() => {
    // User must be associated with a merchant to use SoftPos
    return user.value?.merchantId != null
  })

  const isAuthorizedPos = computed(() => {
    if (user.value != null) {
      return user.value.emailConfirmed &&
        user.value.merchantActive &&
        user.value.merchantId != null &&
        user.value.locationId != null &&
        user.value.staffId != null &&
        user.value.cashRegisterId != null &&
        user.value.deviceId != null
    }

    return false
  })

  const defaultCurrency = computed(() => {
    return location.value?.currencyCode ?? 'SEK'
  })

  function setUserinfo (data: UserInfo) {
    if (data.features) {
      featureStore.setFeatures(data.features)
    }
    user.value = data
  }

  function setAuthPending (p: boolean) {
    authPending.value = p
  }

  function setIsKeypadOpen (open: boolean) {
    isKeypadOpen.value = open
  }

  function setKeypadValue (value: string) {
    keypadValue.value = value
  }

  function clearUser () {
    user.value = null
    setReturnTo(undefined)
  }

  const merchant = ref<MerchantResponse>()

  function setMerchant (m: MerchantResponse) {
    merchant.value = m
  }

  const location = ref<LocationResponse>()

  function setLocation (loc: LocationResponse) {
    location.value = loc
  }

  const loaders = ref(0)

  function addLoader () {
    loaders.value++
  }

  function removeLoader () {
    if (loaders.value > 0) {
      loaders.value--
    }
  }

  const hubState = ref<string>()
  function setHubState (val: string | undefined) {
    hubState.value = val
  }

  const hasInteraction = ref(false) // User has to interact with UI for us to play sounds

  const now = ref(Date.now())

  function updateClock() {
    now.value = Date.now()
  }

  function initClock() {
    setInterval(() => updateClock(), 1000)
  }

  function setReturnTo(path: string | undefined) {
    returnTo.value = path
  }

  initClock()

  return {
    locale,
    defaultCurrency,
    user,
    authPending,
    setAuthPending,
    setUserinfo,
    clearUser,
    returnTo,
    setReturnTo,
    isAuthenticated,
    isActive,
    isMerchantUser,
    isAuthorizedPos,
    merchant,
    setMerchant,
    setLocation,
    location,

    loaders,
    addLoader,
    removeLoader,

    setHubState,
    hubState,

    hasInteraction,
    now,

    isKeypadOpen,
    setIsKeypadOpen,
    keypadValue,
    setKeypadValue
  }
})
