export default {
  itemInfo: 'Article info',
  price: 'Price',
  discount: 'Discount',
  cashier: 'Cashier',
  tickets: 'Orders',
  totalAmount: 'Total',
  addArticles: 'Add more',
  addArticlesFirst: 'Add',
  confirm: 'Confirm',
  confirmOk: 'Saved',
  lockedLabel: 'Locked',
  lockedDialogTitle: 'Tab locked',
  lockedDialogDescription: 'Tab is locked by {lockedBy} at {lockedAt}, "{lockedReason}".',
  unlock: 'Unlock',
  lockReasonPaymentStarted: 'Payment started',
  unlockSuccess: 'Tab unlocked',
  confirmDiscardTitle: 'Please note',
  confirmDiscardDescription: 'You have some unconfirmed articles',
  confirmAndPay: 'Pay',
  conflictMessage: 'Tab was updated elsewhere, try again',
  discard: 'Discard',
  cancelOrder: 'Cancel order',
  tabName: 'Tab {tabNumber}',
  printTicket: {
    title: 'Print ticket - Categories',
    all: 'All',
    other: 'Other',
    print: 'Print'
  },
  menuItems: {
    printProforma: 'Print proforma',
    printTicket: 'Print ticket',
    info: 'Tab info',
    split: 'Split tab',
    move: 'Move tab'
  },
  sourceTab: 'source',
  targetTab: 'target'
}
