import kds from './kds'

export default {
  kds,
  order: 'Order',
  orders: 'Orders',
  orderStatus: 'Order status',
  printTicket: 'Print ticket',
  email: 'E-mail',
  refund: 'Refund',
  receipt: 'Receipt',
  articles: 'Articles',
  information: 'Information',
  deliverFullOrder: 'Deliver full order',
  unDeliverFullOrder: 'Un-deliver full order',
  emptyRightNow: 'Empty right now.',
  time: 'Time',
  sortTime: 'Time',
  sortName: 'Name',
  delivery: 'Delivery',
  total: 'Total',
  orderCode: 'Order code',
  qty: 'QTY',
  of: 'of',
  undefined: 'Undefined',
  notDelivered: 'Not delivered',
  delivered: 'Delivered',
  categoryOther: 'Other',
  confirm: {
    one: 'CONFIRM ORDER',
    many: 'CONFIRM ALL NEW ORDERS'
  },
  status: {
    started: 'Started',
    cancelled: 'Cancelled',
    success: 'Success',
    created: 'Created',
    waiting: 'Waiting for tip',
    delivery: {
      platform: {
        locked: 'Delivery status for Wolt, Uber and Foodora orders cannot be changed manually'
      }
    }
  },
  menu: {
    all: 'All',
    recent: 'Recent',
    done: 'Done',
    filter: 'Filter',
    sound: 'Sound',
    off: 'off',
    on: 'on',
    pos: 'POS',
    kiosk: 'Kiosk',
    online: 'Online',
    origin: 'Origin',
    preparation: 'Preparation',
    grid: 'Grid',
    layoutKds: 'KDS',
    list: 'List',
    listMini: 'Mini list',
    layout: 'Layout',
    kdsOrders: 'Show orders by default',
    ubereats: 'UberEats',
    foodora: 'Foodora',
    wolt: 'Wolt'
  },
  details: {
    dateCreated: 'Date created',
    dateModified: 'Date modified',
    paymentMethod: 'Payment method',
    pspId: 'PSP Trans.Id',
    orderCode: 'Order code',
    currencyRate: 'Currency rate',
    currencyCode: 'Currency code',
    paymentStatus: 'Payment status',
    deliveryStatus: 'Delivery status',
    note: 'Note',
    amount: 'Amount',
    orderNumber: 'Order number',
    delivery: {
      nothing: 'Nothing delivered',
      partially: 'Partially delivered',
      done: 'Done, All delivered'
    }
  },
  manageDelivery: {
    title: 'Manage delivery order',
    titlePreOrder: 'Manage pre-order delivery',
    titlePending: 'Manage pending delivery',
    titleAccepted: 'Manage accepted delivery',
    buttonManage: 'Manage',
    buttonReady: 'Ready for pickup',
    buttonAccept: 'Accept',
    buttonDeny: 'Deny',
    accept: 'Accept',
    deny: 'Deny',
    confirm: 'Confirm',
    deliveryId: 'Delivery ID',
    created: 'Created',
    status: 'Status',
    consumer: 'Customer',
    pickupTime: 'Pickup time',
    pickupTimeMinutes: 'Pick in (minutes)',
    denyReason: 'Reason',
    denyReasonText: 'Reason text',
    confirmCancel: 'Confirm cancel',
    confirmCancelDescription: 'Are you sure about cancelling the delivery order?',
    cancelOrderButton: 'Cancel order',
    cancelReason: 'Reason',
    cancelReasonText: 'Reason text',
    estimatedPickupTimeLabel: 'Estimated pickup: ',
    buttonDelivered: 'Mark as delivered',
    orderDeliveredSuccess: 'Order marked as delivered',
    orderReadySuccess: 'Order marked as ready for pickup',
    orderAcceptedSuccess: 'Order accepted successfully',
    orderDeniedSuccess: 'Order denied successfully',
    preOrderConfirmed: 'Pre-order confirmed successfully',
    confirmPreOrder: 'CONFIRM PRE-ORDER',
    confirmOrder: 'CONFIRM ORDER',
    acceptButton: 'ACCEPT',
    denyButton: 'DENY',
    cancelOrder: 'CANCEL ORDER',
    autoAccept: 'Auto-accept delivery orders',
    minutes: 'minutes',
    errors: {
      default: 'Failed to update delivery order status',
      badRequest: 'Invalid request data',
      orderNotFound: 'Order not found',
      invalidOrder: 'Invalid order information',
      apiMethodNotFound: 'System error: Unable to process delivery order',
      cannotUpdateFoodoraStatus: 'Cannot manually update delivery status for Foodora orders'
    },
    success: {
      accepted: 'Order accepted successfully',
      denied: 'Order denied successfully',
      preOrderConfirmed: 'Pre-order confirmed successfully'
    },
    notifications: {
      orderAccepted: 'Order {id} accepted successfully',
      orderDenied: 'Order {id} denied successfully',
      preOrderConfirmed: 'Pre-order {id} confirmed successfully'
    },
    orderStatus: {
      pending: 'Pending',
      preparing: 'Preparing',
      readyForPickup: 'Ready for pickup',
      completed: 'Completed',
      cancelled: 'Cancelled',
      failed: 'Failed',
      rejected: 'Rejected',
      refunded: 'Refunded',
      unknown: 'Unknown',
      preOrderConfirmed: 'Pre-order confirmed',
      preOrderPending: 'Pre-order pending'
    }
  },
  errors: {
    fetchFailed: 'Failed to fetch current orders',
    processingFailed: 'Failed to process orders data'
  }
}
