export default {
  allArticles: 'Alla artiklar',
  refresh: 'Ladda om',
  cancel: 'Avbryt',
  ok: 'OK',
  clear: 'Rensa',
  close: 'Stäng',
  back: 'Tillbaka',
  delete: 'Ta bort',
  discard: 'Kasta',
  confirm: 'Bekräfta',
  more: 'Mer',
  search: 'Sök',
  monday: 'Måndag',
  tuesday: 'Tisdag',
  wednesday: 'Onsdag',
  thursday: 'Torsdag',
  friday: 'Fredag',
  saturday: 'Lördag',
  sunday: 'Söndag',
  email: 'E-post',
  loading: 'Laddar',
  date: 'Datum',
  retry: 'Försök igen',
  exit: 'Avsluta'
}
