export default {
  itemInfo: 'Artikelinfo',
  price: 'Pris',
  discount: 'Rabatt',
  cashier: 'Kassör',
  tickets: 'Ordrar',
  totalAmount: 'Totalt',
  addArticles: 'Lägg till mer',
  addArticlesFirst: 'Lägg till',
  confirm: 'Bekräfta',
  confirmOk: 'Sparad',
  lockedLabel: 'Låst',
  lockedDialogTitle: 'Nota låst',
  lockedDialogDescription: 'Nota är låst av {lockedBy} kl {lockedAt}, "{lockedReason}".',
  unlock: 'Lås upp',
  lockReasonPaymentStarted: 'Betalning påbörjad',
  unlockSuccess: 'Nota upplåst',
  confirmDiscardTitle: 'Notera',
  confirmDiscardDescription: 'Ni har några obekräftade artiklar',
  confirmAndPay: 'Betala',
  conflictMessage: 'Nota har uppdaterats någon annanstans, försök igen',
  discard: 'Rensa',
  cancelOrder: 'Avbryt order',
  tabName: 'Nota {tabNumber}',
  printTicket: {
    title: 'Skriv ut bong - Kategorier',
    all: 'Allt',
    other: 'Övrigt',
    print: 'Skriv ut'
  },
  menuItems: {
    printProforma: 'Skriv ut förnota',
    printTicket: 'Skriv ut bong',
    info: 'Notainfo',
    split: 'Dela nota',
    move: 'Flytta nota'
  },
  sourceTab: 'källa',
  targetTab: 'mål',
}
