export default {
  total: 'Total',
  payWithMobile: 'Pay with mobile',
  payWithSwish: 'Pay with Swish',
  scanWithYourMobileCamera: 'Scan with your mobile camera',
  scanWithSwishApp: 'Scan with Swish app',
  thanksForYourPayment: 'Thanks for your payment!',
  digitalReceipt: 'Digital receipt',
  forPrintedReceiptTellTheCashier: 'For printed receipt, tell the cashier',
  digitalReceiptIsInYourMobilePhone: 'Digital receipt is in your mobile phone',
  ThanksForYourPatience: 'Thanks for your patience',
  processing: 'Processing...',
  payAtTheCardTerminal: 'Pay at the card terminal...',
  waitingForSignalFromTheCardTerminal: 'Waiting for signal from the card terminal'
}
